import Cookies                         from "js-cookie";
import React, { Component }            from 'react';
import FooterMenu                      from '../../components/FooterMenu';
import AppConfig, { connectAppConfig } from '../../../../shared/components/AppConfig';

class FooterMenuContainer extends Component {
  state = {
    items: [
      [
        {
          name: 'О компании',
          link: '/page/about',
        },
        {
          name: 'Доставка',
          link: '/page/delivery',
        },
        {
          name: 'Как купить',
          link: '/page/how',
        },
        {
          name: 'Франшиза',
          link: '/page/franchise',
        },
      ],

      [
        {
          name: 'Контактная информация',
          link: '/page/contacts',
        },
        {
          name: 'Гарантия и возврат',
          link: '/page/guarantee',
        },
        {
          name: 'Политика конфиденциальности',
          href: this.props.config.POLICY,
        },
        {
          name: 'Пользовательское соглашение',
          href: this.props.config.TERMS,
        },
      ],

      [
        {
          name: 'Что с моим заказом?',
          link: '/check_order',
        },
        {
          name: 'Новости',
          link: '/news',
        },
        {},
        {
          name:   'Мобильная версия',
          action: () => {
            const { config } = this.props;
            const expires = new Date();
            expires.setYear(expires.getFullYear() + 1);
            Cookies.set(`env-${config.ID}`, 'mobile', { domain: config.DOMAIN, expires });
            window.location.reload();
          },
        },
      ],
    ],
  };


  render() {
    return <FooterMenu items={this.state.items} />;
  }
}

export default connectAppConfig(FooterMenuContainer);
