import React, { Component } from 'react';
import PropTypes            from 'prop-types';
import style                from './style.styl';

import FooterMenuItem from '../FooterMenuItem';

class FooterMenu extends Component {
  static propTypes = {
    items: PropTypes.arrayOf(
      PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
          link: PropTypes.string,
        }),
      ),
    ),
  };

  renderColumn = ( column, i ) => {
    return (
      <div key={i} className={style.menu_col}>
        {column.map(( item, j ) => <FooterMenuItem key={j} item={item} />)}
      </div>
    );
  };

  render() {
    return (
      <div className={style.menu}>
        {this.props.items.map(this.renderColumn)}
      </div>
    );
  }
}

export default FooterMenu;
