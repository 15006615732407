import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import style from './style.styl';

export default function FooterMenuItem({ item }) {
  if (item.href) {
    return <a className={style.link} href={item.href} target="_blank">{item.name}</a>;
  }
  if (item.link) {
    return <Link className={style.link} to={item.link}>{item.name}</Link>;
  }
  if (item.action) {
    return <div className={style.link} onClick={item.action}>{item.name}</div>;
  }
  return <div className={style.placeholder}>{'\u00A0'}</div>;
}

FooterMenuItem.propTypes = {
  item: PropTypes.shape({
    name: PropTypes.string,
    link: PropTypes.string,
  }),
};
